<template>
  <form>
    <md-card :class="{ 'device-data-container': this.$route.name == 'matriz' }">
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-medium-size-100 md-size-100">
            <v-tabs v-model="tab">
              <v-tab>{{ $t("device.deviceTab.latestMonitoring") }}</v-tab>
              <v-tab>{{ $t("device.deviceTab.historicalMonitoring") }}</v-tab>
            </v-tabs>
            <v-tabs-items style="margin-top: 2rem" v-model="tab">
              <v-tab-item>
                <div class="row pt-5 pb-5 pr-1 pl-1">
                  <template v-for="s in numberSlots">
                    <div :key="s.id" class="col-12 col-sm-6 col-lg-4 col-xl-3">
                      <number :deviceSlot="s" :deviceId="nodeId" />
                    </div>
                  </template>
                  <template v-for="s in batterySlots">
                    <div :key="s.id" class="col-12 col-sm-6 col-lg-4 col-xl-3">
                      <battery :deviceSlot="s" />
                    </div>
                  </template>
                  <template v-for="s in signalSlots">
                    <div :key="s.id" class="col-12 col-sm-6 col-lg-4 col-xl-3">
                      <signal :deviceSlot="s" />
                    </div>
                  </template>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="
                    row
                    d-flex
                    justify-content-center
                    spinner-container
                    align-items-center
                  " v-if="isLoading">
                  <breeding-rhombus-spinner :animation-duration="1500" :size="50" color="#23c577" />
                </div>

                <div class="md-layout" v-show="!isLoading">
                  <div class="md-layout-item md-small-size-100 md-size-100">
                    <date-picker class="date-picker-container" v-model="dateFilter" type="datetime"
                      :placeholder="$t('device.deviceMonitoring.dateTimeRange')" range
                      :show-time-panel="showTimeRangePanel" :open.sync="open" @close="handleRangeClose">
                      <template v-slot:footer>
                        <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
                          {{
      showTimeRangePanel
        ? $t("device.deviceMonitoring.selectDate")
        : $t("device.deviceMonitoring.selectTime")
    }}
                        </button>
                        <button class="mx-btn mx-btn-text" @click="closeDatePicker">
                          {{ $t("device.deviceMonitoring.close") }}
                        </button>
                      </template>
                    </date-picker>
                  </div>
                  <template v-for="s in lineChartSlots">
                    <div :key="s.id" class="col-12">
                      <multi-slot-lineplot :from="dateFilter[0]" :to="dateFilter[1]" :displayer="s"
                        @loaded="isLoading = false" :downtimes="downtimeEvents" :tripEvents="tripEvents" />
                    </div>
                  </template>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import Battery from "../Monitoring/Battery";
import Signal from "../Monitoring/Signal";
import Number from "../Monitoring/Number";
import { mapGetters } from "vuex";

import { BreedingRhombusSpinner } from "epic-spinners";
import MultiSlotLineplot from "../Monitoring/MultiSlotLineplot.vue";
//test class
import { PlotlyHelper } from "/src/utils/multislotlineplot/plotlyhelper.js";
import { mockdata } from "@/utils/multislotlineplot/mockdata.js";

export default {
  name: "device-data",
  components: {
    Battery,
    Signal,
    Number,
    BreedingRhombusSpinner,
    MultiSlotLineplot,
  },
  props: {
    dataBackgroundColor: {
      type: String,
      default: () => '',
    },
    displayers: {
      type: Array,
      default: () => [],
    },
    nodeId: {
      type: Number,
      required: false,
      default: () => null,
    }
  },
  mounted() {
    this.divideDisplayers();
    this.setTab();
    this.set48HrsDefault();
    this.getDowntimeEvents();
    this.getTripEvents();
  },
  watch: {
    displayers() {
      this.divideDisplayers();
    },
    dateFilter() {
      this.isLoading = true;
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
    to() {
      return this.dateFilter[1];
    },
    from() {
      return this.dateFilter[0];
    },
  },
  data() {
    return {
      batterySlots: [],
      signalSlots: [],
      numberSlots: [],
      lineChartSlots: [],
      areaChartSlots: [],
      multiline: [],
      tab: null,
      dateFilter: [],
      open: false,
      showTimePanel: false,
      showTimeRangePanel: false,
      isLoading: true,
      downtimeEvents: null,
      tripEvents: null,
    };
  },
  methods: {
    getDowntimeEvents() {
      // return [];
      const organizationId = this.$store.getters["user/user"].organization_id;
      const deviceIdentifier = this.displayers[0].slots[0].deviceIdentifier;

      return this.$store
        .dispatch("downtimeEvents/getDowntimeEvents", {
          identifier: deviceIdentifier,
          options: {
            from: this.from,
            to: this.to,
            organizationId: organizationId,
          },
        })
        .then((data) => {
          const events =
            data.map((event) => {
              return event.timestamp;
            }) ?? [];
          this.downtimeEvents = events;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getTripEvents() {
      const deviceIdentifier = this.displayers[0].slots[0].deviceIdentifier;
      this.$store
        .dispatch("events/getEvents", {
          identifier: deviceIdentifier,
          options: {
            from: this.from,
            to: this.to,
          },
        })
        .then((res) => {
          console.log("getTrip data", res);
          this.tripEvents = res.filter(
            (event) => event.type === "end" || event.type === "start"
          );
        })
        .catch((err) => {
          console.error("get trip err", err);
        });
    },

    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    handleRangeClose() {
      this.getDowntimeEvents();
      this.showTimeRangePanel = false;
    },
    closeDatePicker() {
      this.open = false;
    },
    divideDisplayers() {
      this.batterySlots = [];
      this.signalSlots = [];
      this.numberSlots = [];
      this.lineChartSlots = [];
      this.areaChartSlots = [];
      this.displayers.forEach((displayer) => {
        switch (displayer.displayModeId) {
          case 6:
            this.batterySlots.push(displayer);
            break;
          case 7:
            this.signalSlots.push(displayer);
            break;
          case 5:
            this.numberSlots.push(displayer);
            break;
          case 1:
            this.lineChartSlots.push(displayer);
            break;
          case 2:
            this.areaChartSlots.push(displayer);
            break;
          default:
            this.numberSlots.push(displayer);
        }
      });
    },
    setTab() {
      if (this.$route.params.tab) this.tab = this.$route.params.tab;
    },
    set48HrsDefault() {
      let from = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
      let to = new Date();

      this.dateFilter = [from, to];
    },
  },
};
</script>

<style lang="sass" scoped>
.device-data-container
  max-height: 73vh
  overflow-y: scroll
</style>