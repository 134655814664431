<template>
  <div>
    <v-card elevation="5" class="rounded-lg monitoring-card">
      <h4 v-text="deviceSlot.layoutConfiguration.title"></h4>
      <div class="row d-flex justify-content-center mt-3">
        <v-icon color="primary" size="100" v-if="measurement <= 1">
          mdi-signal-off</v-icon
        >
        <v-icon color="primary" size="100" v-if="measurement === 2">
          mdi-signal-cellular-1</v-icon
        >
        <v-icon color="primary" size="100" v-if="measurement === 2">
          mdi-signal-cellular-2</v-icon
        >
        <v-icon color="primary" size="100" v-if="measurement === 2">
          mdi-signal-cellular-3</v-icon
        >
        <v-icon color="primary" size="100" v-if="measurement >= 5">
          mdi-signal</v-icon
        >
      </div>

      <p v-if="timestamp" class="text-center measurement-timestamp">
        {{ timestamp }}
      </p>
      <p class="text-center" v-else>No data available</p>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "signal",
  props: {
    deviceSlot: Object,
  },
  watch: {
    deviceSlot: function () {
      this.measurement = null;
      clearInterval(this.interval);
      this.setComponent();
    },
  },
  data() {
    return {
      measurement: null,
      interval: null,
      timestamp: "",
    };
  },
  methods: {
    setComponent() {
      this.getSlotMeasurement();
      this.interval = setInterval(() => this.getSlotMeasurement(), 30000);
    },
    getSlotMeasurement() {
      this.$store
        .dispatch("monitoring/getSlotMeasurement", this.deviceSlot.slots[0].id)
        .then((data) => {
          if (data.measurement) {
            this.measurement = Number(data.measurement.calibrated_measurement);
            this.timestamp = data.measurement.timestamp;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.setComponent();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>