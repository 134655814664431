<template>
  <div>
    <div class="chart-header"></div>
    <md-button v-if="plotData" @click="exportCSV">Descargar CSV</md-button>

    <plotly-graph v-if="plotData && layout" ref="plotlyGraph" :layout="layout" :plotData="plotData"
      :plotlyConfig="plotlyConfig" />
  </div>
</template>

<script>
const icon1 = {
  width: 500,
  height: 600,
  path: "M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z",
};

import { exportCSVFile } from "../../utils/jsonToCsv";
import PlotlyGraph from "./PlotlyGraph.vue";

// import { PlotlyHelper } from "/src/utils/multislotlineplot/plotlyhelper.js";

import {
  PlotlyHelper,
  AlterBackground,
  Downtimes,
  Trips,
} from "@tgl/tgc-plotlyhelper";

export default {
  name: "MultiSlotLineplot",
  components: {
    PlotlyGraph,
  },
  mixins: [],
  props: {
    displayer: Object,
    from: Date,
    to: Date,
    isLoading: Boolean,
    internalId: String,
    downtimes: {
      type: Array,
      default: () => [],
    },
    tripEvents: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    displayer: function () {
      clearInterval(this.interval);
      this.setComponent();
    },
    downtimes: {
      handler: function (newval) {
        if (this.myplotlyHelper) {
          this.plugins = [
            new AlterBackground(),
          ];
          this.myplotlyHelper.init(
            this.slotsData,
            { ...this.displayer.layoutConfiguration },
            {
              plugins: this.plugins,
            }
          );
          this.plotData = this.myplotlyHelper.slotsData;
          this.layout = this.myplotlyHelper.layout;
        }
      },
      deep: true,
    },
    showDowntimeLines: function (newval) {
      if (this.myplotlyHelper) {
        this.plugins = [
          new AlterBackground(),
        ];
        this.myplotlyHelper.init(
          this.slotsData,
          { ...this.displayer.layoutConfiguration },
          {
            plugins: this.plugins,
          }
        );
        this.plotData = this.myplotlyHelper.slotsData;
        this.layout = this.myplotlyHelper.layout;
      }
    },
    tripEvents: {
      handler: function (newval) {
        let backgroundPlugin = new AlterBackground();
        let downtimesPlugin = new Downtimes(this.downtimes);
        let tripsPlugin = new Trips(newval);
        this.plugins = [backgroundPlugin];
        if (this.myplotlyHelper) {
          this.myplotlyHelper.init(
            this.slotsData,
            { ...this.displayer.layoutConfiguration },
            {
              plugins: this.plugins,
            }
          );
          this.plotData = this.myplotlyHelper.slotsData;
          this.layout = this.myplotlyHelper.layout;
        }
      },
      deep: true,
    },
    slotsData: function () {
      if (this.myplotlyHelper) {
        this.myplotlyHelper.init(
          this.slotsData,
          { ...this.displayer.layoutConfiguration },
          {
            plugins: this.plugins,
          }
        );
        this.plotData = this.myplotlyHelper.slotsData;
        this.layout = this.myplotlyHelper.layout;
      } else {
        this.plotData = [];
      }
    },
    from: function () {
      if (this.timeoutBetweenRequests <= 0) {
        this.timeoutBetweenRequests = 1000;
        this.requestWithDelay();
      }
    },
    to: function () {
      if (this.timeoutBetweenRequests <= 0) {
        this.timeoutBetweenRequests = 1000;
        this.requestWithDelay();
      }
    },
  },
  data() {
    return {
      interval: null,
      layoutMonitoring: {},
      watchShallow: false,
      autoResize: true,
      firstRequest: true,
      timeoutBetweenRequests: 0,
      slotsData: [],
      plotData: null,
      layout: {
        autosize: true,
      },
      myplotlyHelper: null,
      plugins: [],
      showDowntimeLines: false,
      mobileLayout: {
        // paper_bgcolor: "#42f5a1",
        // plot_bgcolor: "#ffefc7",
        // margin: {
        //   b: 30,
        //   t: 30,
        //   l: 10,
        //   r: 10,
        //   pad: 0,
        // },
        // autosize: true,
      },
    };
  },
  methods: {
    setComponent() {
      const slots = this.displayer.slots;
      this.slotsData = [];
      slots.forEach((slot) => {
        this.makeRequest(slot.id)
          .then((slotdata) => {
            this.slotsData.push({
              ...slotdata,
              name: `${slot.alias}::${slot.deviceAlias}`,
              traceAttributes: (slot.traceAttributes ??= null),
            });
          })
          .then(() => {
            this.$emit("loaded");
          });
      });
    },
    makeRequest(slotId) {
      let from = this.from;
      let to = this.to;

      return this.$store
        .dispatch("monitoring/getMeasurements", {
          deviceSlotId: slotId,
          from: from,
          to: to,
        })
        .then((data) => {
          let measurements = data.measurements;
          this.firstRequest = false;
          const slotdata = this.formatSlotMeasurements(measurements);
          return slotdata;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatSlotMeasurements(measurements) {
      let slotData = {
        x: [],
        y: [],
        type: "scatter",
      };
      if (measurements.length > 0) {
        measurements.forEach((measurement) => {
          if (Number(measurement.calibrated_measurement) > 1000) return
          slotData.y.push(Number(measurement.calibrated_measurement));
          slotData.x.push(measurement.timestamp);
        });
      } else {
        slotData.isEmpty = true;
        slotData.showNoData = true;
      }
      return slotData;
    },
    requestWithDelay() {
      setTimeout(() => {
        if (this.timeoutBetweenRequests <= 0) {
          this.timeoutBetweenRequests = 0;
          clearInterval(this.interval);
          this.setComponent();
        } else {
          this.timeoutBetweenRequests = this.timeoutBetweenRequests - 100;
          this.requestWithDelay();
        }
      }, 100);
    },
    exportCSV(e) {
      e.preventDefault();
      const headers = {
        d: "Device",
        s: "Slot",
        z: "Epoch",
        x: "Timestamp",
        y: "Value",
      };

      const epoch = (date) => {
        return new Date(date).getTime();
      };

      let data = [];
      this.slotsData.forEach((slot) => {
        const slotAlias = slot.name.split("::");
        let slotData = [];
        slot.x.forEach((x, i) => {
          slotData.push({
            d: slotAlias[1],
            s: slotAlias[0],
            z: epoch(x),
            x: x,
            y: slot.y[i],
          });
        });
        data.push(...slotData);
      });
      exportCSVFile(headers, data, "Data");
    },
    onDowntimeButtonClick() {
      this.showDowntimeLines = !this.showDowntimeLines;
    },
  },
  created() { },
  mounted() {
    this.setComponent();
    //setup plugins
    let backgroundPlugin = new AlterBackground();
    let downtimesPlugin = new Downtimes(this.downtimes);
    let tripsPlugin = new Trips(this.tripEvents);
    this.plugins = [backgroundPlugin];
    //setup plotly helper
    this.myplotlyHelper = new PlotlyHelper(
      this.slotsData,
      { ...this.displayer.layoutConfiguration },
      {
        plugins: this.plugins,
      }
    );
    this.layout = this.myplotlyHelper.layout;
    this.plotData = this.myplotlyHelper.slotsData;
  },
  computed: {
    plotlyConfig() {
      return {
        responsive: true,
        modeBarButtonsToAdd: [
          {
            name: 'Show Downtimes',
            icon: icon1,
            click: () => { this.onDowntimeButtonClick() },
            direction: 'up'
          }
        ]
      };
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>