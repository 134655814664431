<template>
  <div>
    <v-card elevation="5" class="rounded-lg monitoring-card">
      <div class="displayer-header">
        <v-icon
          color="primary"
          size="20"
          v-if="measurement > 0 && measurement <= 10"
          class="icon"
        >
          mdi-battery-10
        </v-icon>
        <v-icon
          color="primary"
          size="20"
          class="icon"
          v-if="measurement > 10 && measurement <= 20"
        >
          mdi-battery-20
        </v-icon>
        <v-icon
          color="primary"
          size="20"
          v-if="measurement > 20 && measurement <= 30"
          class="icon"
        >
          mdi-battery-30
        </v-icon>
        <v-icon
          color="primary"
          size="20"
          v-if="measurement > 30 && measurement <= 40"
          class="icon"
        >
          mdi-battery-40
        </v-icon>
        <v-icon
          color="primary"
          size="20"
          v-if="measurement > 40 && measurement <= 50"
          class="icon"
        >
          mdi-battery-50
        </v-icon>
        <v-icon
          color="primary"
          size="20"
          v-if="measurement > 50 && measurement <= 60"
          class="icon"
        >
          mdi-battery-60
        </v-icon>
        <v-icon
          color="primary"
          size="20"
          v-if="measurement > 60 && measurement <= 70"
          class="icon"
        >
          mdi-battery-70
        </v-icon>
        <v-icon
          color="primary"
          size="20"
          v-if="measurement > 70 && measurement <= 80"
          class="icon"
        >
          mdi-battery-80
        </v-icon>
        <v-icon
          color="primary"
          size="20"
          v-if="measurement > 80 && measurement <= 90"
          class="icon"
        >
          mdi-battery-90
        </v-icon>
        <v-icon
          color="primary"
          size="20"
          v-if="measurement > 90 && measurement <= 100"
          class="icon"
        >
          mdi-battery
        </v-icon>
        <v-icon color="primary" size="20" v-if="!measurement" class="icon">
          mdi-battery
        </v-icon>
        <h4 v-text="deviceSlot.layoutConfiguration.title"></h4>
      </div>
      <template v-if="timestamp">
        <div class="measurement">
          <p class="text-center number">{{ measurementDisplayed }}<br /></p>
          <p class="text-center symbol">%<br /></p>
        </div>
        <p class="text-center timestamp">{{ timestamp }}</p>
      </template>
      <template v-else>
        <p class="text-center no-data">No data available</p>
      </template>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "battery",
  props: {
    deviceSlot: Object,
  },
  watch: {
    user: {
      handler() {
        if (this.user.organization_id) this.getDevice();
      },
      deep: true,
    },
    device: {
      handler() {
        if (this.user.organization_id) this.subscribe(this.device.identifier);
      },
    },
  },
  computed: {
    measurementDisplayed() {
      return this.trunc(this.measurement, 2);
    },
    ...mapGetters("user", ["user"]),
  },
  data() {
    return {
      measurement: null,
      timestamp: "",
      device: {},
    };
  },
  methods: {
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          this.device = data;
        })
        .catch((error) => {
          console.err(error);
        });
    },
    getSlotMeasurement() {
      this.$store
        .dispatch("monitoring/getSlotMeasurement", this.deviceSlot.slots[0].id)
        .then((data) => {
          if (data.measurement) {
            this.measurement = Number(data.measurement.calibrated_measurement);
            this.timestamp = data.measurement.timestamp;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    trunc(x, posiciones = 0) {
      var s = x.toString();
      var l = s.length;
      var decimalLength = s.indexOf(".") + 1;

      if (l - decimalLength <= posiciones) {
        return x;
      }
      // Parte decimal del número
      var isNeg = x < 0;
      var decimal = x % 1;
      var entera = isNeg ? Math.ceil(x) : Math.floor(x);
      // Parte decimal como número entero
      // Ejemplo: parte decimal = 0.77
      // decimalFormated = 0.77 * (10^posiciones)
      // si posiciones es 2 ==> 0.77 * 100
      // si posiciones es 3 ==> 0.77 * 1000
      var decimalFormated = Math.floor(
        Math.abs(decimal) * Math.pow(10, posiciones)
      );
      // Sustraemos del número original la parte decimal
      // y le sumamos la parte decimal que hemos formateado
      var finalNum =
        entera +
        (decimalFormated / Math.pow(10, posiciones)) * (isNeg ? -1 : 1);

      return finalNum;
    },
    subscribe(deviceIdentifier) {
      this.$tgcWebsocket.subscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.up`,
        this.onDeviceEventBattery
      );
    },
    unsubscribe(deviceIdentifier) {
      this.$tgcWebsocket.unsubscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.up`,
        this.onDeviceEventBattery
      );
    },
    onDeviceEventBattery(message) {
      message.body.slots.forEach((slot) => {
        if (slot.id === this.deviceSlot.slots[0].id) {
          this.measurement = slot.measurement.measurement;
          this.timestamp = this.formatDate(message.body.custom_timestamp);
        }
      });
    },
    formatDate(timestamp) {
      let tempDate = new Date(timestamp);
      let date = tempDate.toLocaleDateString("es-es");
      let time = tempDate.toLocaleTimeString("es-es");

      return `${date} -  ${time}`;
    },
  },
  mounted() {
    this.getDevice();
    this.getSlotMeasurement();
  },
  beforeDestroy() {
    this.unsubscribe(this.device.identifier);
  },
};
</script>